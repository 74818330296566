import { useEffect, useState } from 'react';
import axios from 'axios';
import { AppBar, Avatar, Box, CircularProgress, CssBaseline, Grid2, IconButton, Toolbar, Typography } from '@mui/material';
import { AccountCircle, MenuOutlined } from '@mui/icons-material';
import { Link, Route, Routes } from 'react-router-dom';
import DataViewer from './components/dataviewer';
import Login from './components/login';
import AppDrawer from './components/appdrawer';
import Dashboard from './components/dashboard';
import Logo from "./logo_weiss.svg";

function App() {
  const [menu, setMenu] = useState();
  const [user, setUser] = useState();
  const [openLogin, setOpenLogin] = useState(false);
  const [menuVisible, setMenuVisible] = useState(localStorage.getItem("menuvisible") == "true");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUser(user);
  }, [])

  useEffect(() => {
    if (user) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.accesstoken}`;
      axios.defaults.headers.common['containerid'] = user.databaseid;
      setOpenLogin(false);
      loadMenu();
    } else {
      setOpenLogin(true);
    }
  }, [user])

  function loadMenu() {
    axios.get('https://majestyportalapi.azurewebsites.net/data/menu')
      .then((response) => {
        if (response.data.length > 0)
          setMenu(response.data);
        else
          setMenu([]);
      }, (error) => {

      });
  }

  function handleLogin(xUser) {
    localStorage.setItem('user', JSON.stringify(xUser));
    setUser(xUser);
    setOpenLogin(false);
  }

  function handleLogout() {
    setMenu([]);
    localStorage.removeItem("user")
    setUser();
  }

  if (openLogin) {
    return (
      <Login handleLogin={(xUser) => handleLogin(xUser)}></Login>
    )
  }


  if (!menu) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
      }}>
        <CircularProgress></CircularProgress>
      </Box>
    )
  }

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" style={{ background: "#243545" }} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => { localStorage.setItem("menuvisible", !menuVisible); setMenuVisible(!menuVisible) }}
            >
              <MenuOutlined></MenuOutlined>
            </IconButton>
            <Typography variant="h6" noWrap component="div" style={{ marginRight: 16 }}>
              Portal {user ? user.bezeichnung : ''}
            </Typography>
            {/* <Grid2 item>
                <Link href="/" color="white">
                  <Grid2>
                    <Grid2 item>
                      <Typography variant={"subtitle1"} style={{ color: "white" }}>{user ? user.username : ''}</Typography>
                    </Grid2>
                    <Grid2 item>
                      <Typography variant={"subtitle2"} style={{ color: "white" }}>Portal {user ? user.bezeichnung : ''}</Typography>
                    </Grid2>
                  </Grid2>
                </Link>
              </Grid2>             */}
            <Box flex={1}></Box>
            <Grid2 container spacing={2}>
              <Grid2 item>
                <Typography variant="caption">Powered by</Typography>
              </Grid2>
              <Grid2>
              <a href="https://majesty.de" target="_blank" rel="noopener noreferrer">
                <Box
                  component="img"
                  sx={{
                    height: 38,
                  }}
                  alt="Your logo."
                  src={Logo}
                />
                </a>
              </Grid2>
            </Grid2>
          </Toolbar>
        </AppBar>
        {menuVisible &&
          <AppDrawer menu={menu} handleLogout={() => handleLogout()}></AppDrawer>
        }
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Routes>
            <Route path="/:belegtype" element={<DataViewer menu={menu} key={menu.id} menuVisible={menuVisible} />} />
            <Route path="/" element={<Dashboard menu={menu}></Dashboard>} />
          </Routes>
        </Box>
      </Box>
    </div>
  );
}

export default App;
