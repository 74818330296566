import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText,  Toolbar } from '@mui/material';
import { Home, ListAlt, Logout } from '@mui/icons-material';
import { Link, Navigate } from 'react-router-dom';
import { useState } from 'react';

const drawerWidth = 240;

export default function AppDrawer(props)
{
    return (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List>
            <ListItem disablePadding>
                <ListItemButton disablePadding key={"dashboard"} component={Link} to={""}>
                  <ListItemIcon>
                    <Home></Home>
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
              {props.menu.map((menuItem) => {
                return (
                  <ListItem disablePadding key={menuItem.id} component={Link} to={menuItem.id}>
                    <ListItemButton>
                      <ListItemIcon>
                        <ListAlt></ListAlt>
                      </ListItemIcon>
                      <ListItemText primary={menuItem.bezeichnung}></ListItemText>
                    </ListItemButton>
                  </ListItem>
                )
              })}
              <Divider></Divider>
              <ListItem disablePadding>
                <ListItemButton onClick={() => {props.handleLogout()}}>
                  <ListItemIcon>
                    <Logout></Logout>
                  </ListItemIcon>
                  <ListItemText primary="Abmelden" />
                </ListItemButton>
              </ListItem>


            </List>
          </Box>
        </Drawer>        
    )
}