import { LockOutlined } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, CircularProgress, Grid2, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import Logo from "../logo_weiss.svg";

export default function Login(props) {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [error, setError] = useState();

    function login() {
        setLoadingLogin(true);
        axios.post('https://majestyportalapi.azurewebsites.net/user/login', { "username": username, "password": password })
            .then((response) => {
                setLoadingLogin(false);
                if (response.data !== "") {
                    props.handleLogin(response.data)
                } else {
                    setError("Benutzername und/oder Passwort falsch")
                }
            }, (error) => {

            });
    }


    return (
        <Grid2
            container
            direction="column"
            alignItems="center"
            style={{ minHeight: '100vh',  paddingTop: "10vh", backgroundColor: "#243545" }}
        >
            <Box
                component="img"
                sx={{
                    height: 48,
                    pb: 4
                }}
                alt="Your logo."
                src={Logo}
            />
            <Box
                component="form"
                sx={{
                    p: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                    maxWidth: 300,
                    backgroundColor: "white",
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2, // margin bottom for spacing
                    }}
                >
                    {!loadingLogin &&
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                            <LockOutlined />
                        </Avatar>

                    }
                    {loadingLogin &&
                        <CircularProgress></CircularProgress>
                    }
                </Box>
                <Typography variant="h4" gutterBottom align="center">
                    Majesty.Portal
                </Typography>
                <TextField
                    fullWidth
                    label="Benutzername"
                    variant="outlined"
                    margin="normal"
                    required
                    onChange={(event) => { setError(); setUsername(event.target.value) }}
                />
                <TextField
                    fullWidth
                    label="Passwort"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    required
                    onChange={(event) => { setError(); setPassword(event.target.value) }}
                />
                {error &&
                    <Alert severity="error">{error}</Alert>
                }
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loadingLogin}
                    sx={{ mt: 2 }}
                    onClick={() => login()}
                >
                    Login
                </Button>
            </Box>
        </Grid2>
    )
}