import React from 'react';
import { Alert, Card, CardActionArea, CardContent, Grid2, Typography } from '@mui/material'; // Assuming you're using Material-UI

export default function Dashboard(props) {
    if (props.menu.length <= 0)
    {
        return (
            <Alert severity='info'>Für dieses Portal wurden noch keine Daten bereitgestellt oder der Zugang noch nicht aktiviert, bitte probieren Sie es später erneut</Alert>
        )
    }
    return (
        <Grid2 container spacing={2}>
            {props.menu.map((xMenuItem) => (
                <Grid2 item>
                    <Card key={xMenuItem.id} >
                        <CardActionArea href={"/"+xMenuItem.id} sx={{ minWidth: 275, minHeight: 200 }}>
                            <CardContent>
                                <Typography variant="h7">{xMenuItem.bezeichnung}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid2>
            ))}
        </Grid2>
    );
}
