import { AccessTime, FilterAlt, FilterAltOff, Refresh } from "@mui/icons-material";
import { Box, capitalize, Chip, IconButton, Toolbar, Typography, TextField, Grid2, Autocomplete, Tooltip, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from 'clsx';
import styled from "styled-components";

const storedPaginationSize = localStorage.getItem('paginationsize');
let pagination = 15;
if (storedPaginationSize && parseInt(storedPaginationSize) > 0)
    pagination = parseInt(storedPaginationSize);
const paginationModel = { page: 0, pageSize: pagination };

const localizedTextsMap = {
    columnMenuUnsort: "Storierung aufheben",
    columnMenuSortAsc: "Aufsteigend",
    columnMenuSortDesc: "Absteigend",
    columnMenuFilter: "Filter",
    columnMenuHideColumn: "Spalte ausblenden",
    columnMenuShowColumns: "Spalte einblenden",
    MuiTablePagination: {
        labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} von ${count === -1 ? `more than ${to}` : count}`,
        labelRowsPerPage: "Zeilen pro Seite"
    },
};

const BaseStyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .default': {
      backgroundColor: 'white',
    },
  }));

export default function DataViewer(props) {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const { belegtype } = useParams();
    const [data, setData] = useState();
    const [searchColumns, setSearchColumns] = useState([]);
    const [mainData, setMainData] = useState();
    const [columns, setColumns] = useState();
    const [menuItem, setMenuItem] = useState();
    const [searchKeys, setSearchKeys] = useState([]);
    const [searchReadonly, setSearchReadonly] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const apiRef = useGridApiRef();
    const searchRef = useRef();
    const [dataGridsettings, setDataGridsettings] = useState();

    

    const [rowStyles, setRowStyles] = useState({});

      const [StyledDataGrid, setStyledDataGrid] = useState(BaseStyledDataGrid);

      useEffect(() => {
        if (dataGridsettings) {
          const fetchDataAndUpdateStyles = async () => {
            let newStyles = {};
            dataGridsettings[0].beleg.gridsettingcolors.forEach(xDatagridItemRowColor => {
              const sanitizedClassName = `color-${xDatagridItemRowColor.CustomBackColor.replace(/#/g, '')}`;
              newStyles[`& .${sanitizedClassName}`] = {
                backgroundColor: xDatagridItemRowColor.CustomBackColor, // Example dynamic background color
              };
            });
            setRowStyles(newStyles);
    
            const DynamicStyledDataGrid = styled(DataGrid)(({ theme }) => ({
              '& .default': {
                backgroundColor: 'white',
              },
              ...newStyles, // Apply dynamically created styles
            }));
            setStyledDataGrid(() => DynamicStyledDataGrid);
          };
    
          fetchDataAndUpdateStyles();
        }
      }, [dataGridsettings]);

      useEffect(() => {
        if (props.menu) {
            var menuitem = props.menu.find(x => x.id == belegtype);
            setMenuItem(menuitem);
        }
    }, [props.menu, belegtype])

    useEffect(() => {
        setData();
        setSearchColumns([]);
        setMainData();
        setColumns();
        var storedSearchkeys = localStorage.getItem(`${belegtype}_searchkeys`) ? JSON.parse(localStorage.getItem(`${belegtype}_searchkeys`)) : [];
        setSearchKeys(storedSearchkeys);
        setSearchReadonly(true);
        loadData(belegtype)
        if (searchRef.current) {
            if (storedSearchkeys && storedSearchkeys.length > 0) {
                searchRef.current.disabled = false;
                searchRef.current.focus();
            }
            else {
                searchRef.current.disabled = true;
            }
        }
    }, [belegtype])

    useEffect(() => {
        setSearchReadonly(searchKeys.length <= 0);
    }, [searchKeys])

    useEffect(() => {
        if (columns && columns.length > 0) {
            var searchCols = [];
            columns.forEach(column => {
                searchCols.push({ "label": column.headerName, "key": column.field })
            });
            setSearchColumns(searchCols);
        }

    }, [columns])


    useEffect(() => {
        axios.get('https://majestyportalapi.azurewebsites.net/data/menu')
            .then((response) => {
                if (response.data.length > 0) {
                    var menuitem = response.data.find(x => x.id == belegtype);
                    setMenuItem(menuitem);
                }
            }, (error) => {

            });
    }, [data])

    function loadData(xBelegtype) {
        setData();
        let tableColumns = [];
        axios.get('https://majestyportalapi.azurewebsites.net/data/' + xBelegtype)
            .then((response) => {
                setDataGridsettings(response.data.filter(x => x.belegtyp == "Gridsettings"));
                setData(response.data.filter(x => x.belegtyp != "Gridsettings"));
                setMainData(response.data.filter(x => x.belegtyp != "Gridsettings"));
                var datagridsettings = response.data[0].beleg;
                datagridsettings.gridsetting.forEach(xDatagridItem => {
                    if (xDatagridItem.SPropertyType == "System.Decimal" || xDatagridItem.SPropertyType == "System.Int32") {
                        var column = { field: xDatagridItem.Name, type: "number", filterable: false, headerName: capitalize(xDatagridItem.Name), width: xDatagridItem.Width + 40, valueGetter: (value, row) => `${row.beleg[xDatagridItem.Name.toLowerCase()] || '0'}` };
                        tableColumns.push(column);
                    } else if (xDatagridItem.SPropertyType == "System.DateTime") {
                        var column = {field: xDatagridItem.Name, headerName: capitalize(xDatagridItem.Name), filterable: false, width: xDatagridItem.Width + 40, valueGetter: (value, row) => `${new Date(row.beleg[xDatagridItem.Name.toLowerCase()]).toLocaleDateString("de-DE", { year: 'numeric', month: '2-digit', day: '2-digit' }) != '01.01.1' ? new Date(row.beleg[xDatagridItem.Name.toLowerCase()]).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''}` };
                        tableColumns.push(column);
                    } else {
                        var column = {field: xDatagridItem.Name, headerName: capitalize(xDatagridItem.Name), filterable: false, width: xDatagridItem.Width + 40, valueGetter: (value, row) => `${row.beleg[xDatagridItem.Name.toLowerCase()] || ''}` };
                        tableColumns.push(column);
                    }

                });
                setColumns(tableColumns);
            }, (error) => {

            });
    }

    function handleSearch(xValue) {
        if (xValue === "") {
            setData(mainData);
            return;
        }
        const searchValue = xValue.toLowerCase(); // Suchbegriff in Kleinbuchstaben
        if (!Array.isArray(searchKeys) || searchKeys.length === 0) {
            return;
        }

        // Führe die Suche direkt durch, ohne Verzögerung oder Abbruch
        var newData = mainData.filter(x => {
            const beleg = x.beleg;

            // Überprüfe jeden Schlüssel in searchKeys
            return searchKeys.some(key => {
                if (!beleg[key.key.toLowerCase()]) {
                    return false; // Wenn der Schlüssel nicht existiert, weitermachen
                }
                // Verwende .includes() für eine "enthält"-Suche
                return beleg[key.key.toLowerCase()].toString().toLowerCase().includes(searchValue);
            });
        });

        setData(newData); // Update mit den gefilterten Daten
    }

    return (
        <div>
            <Toolbar>
                {menuItem && (
                    <Typography variant="h5">{menuItem.bezeichnung}</Typography>
                )}
                <IconButton onClick={() => loadData(belegtype)}>
                    <Refresh />
                </IconButton>
                <Box flex={1} />
                <IconButton onClick={() => setShowFilter(!showFilter)}>
                    {!showFilter ? (
                        <Tooltip title={"Filter einblenden"}>
                            <FilterAlt />
                        </Tooltip>
                    ) : (
                        <Tooltip title={"Filter ausblenden"}>
                            <FilterAltOff />
                        </Tooltip>
                    )}
                </IconButton>
                {!isSmallScreen && menuItem && (
                    <Tooltip title={"Letzte Synchronisation"}>
                        <Chip
                            icon={<AccessTime />}
                            label={`${menuItem.lastsync} Uhr`}
                        />
                    </Tooltip>
                )}
            </Toolbar>
            <Grid2 container spacing={2} size={12}>
                {showFilter &&
                    <>
                        <Grid2 item size={8}>
                            <TextField
                                inputRef={searchRef}
                                density
                                autoFocus
                                fullWidth
                                size="small"
                                label="Filterwert"
                                onChange={(event) => handleSearch(event.target.value)}
                            />
                        </Grid2>
                        <Grid2 item size={4}>
                            <Autocomplete
                                multiple
                                fullWidth
                                size="small"
                                disablePortal
                                value={searchKeys}
                                options={searchColumns}
                                onChange={(event, newValue) => {
                                    setSearchKeys(newValue);
                                    localStorage.setItem(`${belegtype}_searchkeys`, JSON.stringify(newValue));
                                    if (searchRef.current) {
                                        if (newValue.length > 0)
                                            searchRef.current.disabled = false;
                                        else
                                            searchRef.current.disabled = true;
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        label="Filter"
                                    />
                                )}
                            />
                        </Grid2>
                    </>

                }
                <Grid2 item size={{ xs: 12 }}>
                    {!columns &&
                        <CircularProgress></CircularProgress>
                    }
                    {columns && (
                        <StyledDataGrid
                            key={belegtype}
                            apiRef={apiRef}
                            density="compact"
                            rows={data}
                            columns={columns}
                            disableColumnSelector={true}
                            initialState={{ pagination: { paginationModel } }}
                            pageSizeOptions={[5, 10, 15, 25, 50, 100]}
                            onPaginationModelChange={(xValue) => { localStorage.setItem('paginationsize', xValue.pageSize) }}
                            sx={{ 
                                maxWidth: props.menuVisible ? 'calc(100vw - 300px)' : 'calc(100vw - 50px)', 
                                height: !showFilter ? 'calc(100vh - 180px)' : 'calc(100vh - 240px)' 
                            }}
                            loading={!data}
                            getRowClassName={(params) => {
                                return params.row.backcolor ? `color-${params.row.backcolor.replace(/#/g, '')}` : '';
                              }}
                            localeText={localizedTextsMap}
                            labelRowsPerPage="Zeilen pro Seite"
                            slotProps={{
                                loadingOverlay: {
                                    variant: 'linear-progress',
                                    noRowsVariant: 'skeleton',
                                },
                            }}
                           />
                    )}
                </Grid2>
            </Grid2>
            {isSmallScreen && (
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    {menuItem && (
                        <Tooltip title={"Letzte Synchronisation"}>
                            <Chip
                                icon={<AccessTime />}
                                label={`${menuItem.lastsync} Uhr`}
                            />
                        </Tooltip>
                    )}
                </Box>
            )}
        </div >

    )
}